<template>
  <div class="relative flex items-center">
    <slot></slot>
    <div class="absolute right-4 pointer-events-none">
      <font-awesome-icon
        :icon="['fal', 'chevron-down']"
        class="text-gray-500"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectDropdown'
};
</script>

<style></style>
