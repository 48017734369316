<template>
  <div class="flex flex-col">
    <div
      class="sticky top-0 bg-white z-20 px-6 default-transition"
      v-scroll-shadow
    >
      <div class="flex sm:items-center flex-col sm:flex-row sm:justify-between">
        <div class="space-x-4 flex items-center w-full">
          <div class="text-xl flex-grow font-semibold py-5">
            Churners
          </div>
          <div class="flex space-x-4 items-center">
            <div class="text-gray-500">Days from Taster</div>
            <select-dropdown class="w-16">
              <select v-model="daysOut" @change="setDays()">
                <option
                  :key="`days-${number}`"
                  v-for="number in 12"
                  :value="number"
                  >{{ number }}</option
                >
              </select>
            </select-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="relative flex flex-col flex-grow mt-6 sm:mt-0 px-6  ">
      <loading :is-loading="isLoading" is-full class="flex-grow flex flex-col">
        <transition name="fade" mode="out-in">
          <div
            class="w-full flex-grow overflow-x-auto"
            v-if="churnList && churnList.length > 0"
            key="churn-users"
          >
            <div class="grid grid-cols-5 auto-cols-min whitespace-nowrap  ">
              <div class=" table-head contents">
                <div
                  class="flex justify-center w-10 px-4 sm:px-0 whitespace-nowrap min-w-min "
                >
                  <input type="checkbox" v-model="selectAll" />
                </div>

                <div class=""></div>

                <div>Email</div>
                <div>Cost</div>
                <div>First Box</div>
              </div>
              <div
                v-for="(item, index) in churnList"
                :key="`item-${index}`"
                class="group flex items-center py-2 hover:bg-gray-50 contents table-item "
                :class="{ 'is-selected': item.isSelected }"
              >
                <div class="flex justify-center w-10">
                  <input type="checkbox" v-model="item.isSelected" />
                </div>

                <router-link
                  tag="div"
                  :to="{
                    name: 'userProfile',
                    params: { id: item.userid, tab: 'activity' }
                  }"
                  class="cursor-pointer group flex items-center py-2 hover:bg-gray-50 contents  "
                >
                  <div class="flex space-x-4 items-center  w-full pr-4">
                    <avatar size="sm" :email="item.email" />

                    <div class="flex-grow">{{ item.name }}</div>
                    <div class="text-gray-400 text-sm">#{{ item.userid }}</div>
                  </div>
                </router-link>
                <div class="text-gray-500">
                  <div class="pr-4">{{ item.email }}</div>
                </div>

                <div class="text-gray-500">
                  {{ item.cost }}
                </div>

                <div class="text-gray-500">
                  {{ formatDate(item.nextbilldate) | timeago }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            key="no-results-reviews"
            class="flex-grow h-full flex items-center justify-center text-gray-400"
          >
            <div>
              No churners at this time
            </div>
          </div>
        </transition>
      </loading>
    </div>
  </div>
</template>

<script>
import { fromUnixTime } from 'date-fns';
import { mapActions, mapGetters } from 'vuex';
import Avatar from '@/components/common/ui/Avatar.vue';
import SelectDropdown from '@/components/common/ui/SelectDropdown.vue';
import Loading from '@/components/common/ui/Loading.vue';
export default {
  components: { Avatar, SelectDropdown, Loading },
  name: 'ChurnList',
  data() {
    return {
      selectAll: false,
      isLoading: true,
      daysOut: this.$store.state.churn.defaultDays
    };
  },
  computed: {
    ...mapGetters({ churnList: 'churnListGetter' })
  },
  methods: {
    ...mapActions({ getChurn: 'getChurnList' }),
    formatDate(date) {
      return fromUnixTime(date / 1000);
    },
    setDays() {
      this.isLoading = true;
      this.getChurn(this.daysOut).then(() => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.getChurn(this.daysOut).then(() => {
      this.isLoading = false;
    });
  }
};
</script>

<style lang="scss" scoped>
.grid {
  &.grid-cols-5 {
    grid-template-columns:
      max-content
      auto min-content
      minmax(min(100px, 100px), max-content)
      minmax(min(120px, 120px), max-content);
  }
  .table-item > * {
    @apply whitespace-nowrap min-w-min  sm:px-0;
  }
  // grid-template-columns: 4fr 2fr 2fr 2fr 2fr;
}
</style>
