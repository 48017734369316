<template>
  <div class="w-full h-full flex flex-col items-center justify-center">
    <div class="text-xl font-semibold mb-4">Approve / Reject</div>
    <div
      class="text-gray-500 text-sm font-mono mb-6 text-center max-w-xl w-full"
    >
      These are customer submitted images. Approve or reject, approved images
      will show publicly. Image quality is reduced here for speed.
    </div>
    <transition
      enter-active-class="miniFadeInUp"
      leave-active-class="miniFadeOutDown"
      mode="out-in"
    >
      <div
        :key="selectedCat.catId"
        v-if="selectedCat"
        class="border rounded-lg p-4"
      >
        <div
          class="w-72 h-72 rounded relative overflow-hidden mb-4 flex items-center justify-center"
        >
          <throbber size="lg" color="dark" />
          <img
            :src="
              getImageUrl(
                selectedCat.image,
                'tr=fo-cat,q-50,ar-1_1,w-300,f-webp'
              )
            "
            class="aspect-square absolute flex-none rounded flex bg-gray-100 object-contain"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <form @submit.prevent="approve(selectedCat.catId, true)">
            <loading-button
              is-full
              secondary
              :is-loading="isLoading"
              :is-enabled="true"
              ><div
                class="text-4xl pb-1 text-center justify-center h-full flex items-center w-full"
              >
                👍🏻
              </div></loading-button
            >
          </form>
          <form @submit.prevent="approve(selectedCat.catId, false)">
            <loading-button
              is-full
              warning
              :is-loading="isLoading"
              :is-enabled="true"
              ><div
                class="text-4xl pt-1 text-center justify-center h-full flex items-center w-full"
              >
                👎🏻
              </div></loading-button
            >
          </form>
        </div>
      </div>
      <div class="h-96 text-gray-500 flex items-center justify-center" v-else>
        No more cats to approve
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoadingButton from '../components/common/ui/LoadingButton.vue';
import Throbber from '../components/common/ui/Throbber.vue';
export default {
  components: { LoadingButton, Throbber },
  name: 'Cats',
  data() {
    return { catsList: [], selectedCat: null, isLoading: false };
  },
  methods: {
    ...mapActions({
      getCats: 'getCatsList',
      approveCat: 'approveCatImage'
    }),
    getImageUrl(image, config) {
      return `${image}?${config}`;
    },
    approve(id, status) {
      this.isLoading = true;
      console.log(id, status);
      this.approveCat({ id: id, approve: status })
        .then(response => {
          this.isLoading = false;
          this.catsList = this.catsList.filter(cat => cat.catId !== id);
          if (this.catsList.length > 0) {
            this.selectedCat = this.catsList[0];
          } else {
            this.selectedCat = null;
          }
        })
        .catch(error => {
          console.log(error.response.data);
        });
    }
  },
  mounted() {
    this.getCats({
      params: { marketingAllowed: true, type: 'needs_reviewing' }
    })
      .then(response => {
        this.catsList = response;
        this.selectedCat = this.catsList[0];
      })
      .catch(error => {
        console.log(error.response.data);
      });
  }
};
</script>

<style></style>
